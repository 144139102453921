<template>
  <div id="datasource-form">
    <div class="search__actions tw-flex tw-justify-end tw-mb-2">
      <b-button
        id="search__clear-all"
        variant="outline-secondary"
        class="mr-1"
        @click="handleClearClick"
      >
        <span class="align-middle">{{ $t('Cancel') }}</span>
      </b-button>
      <b-button
        v-if="!viewOnly"
        id="search__run"
        :class="{ 'icon-disabled': !selectedEntities.length }"
        variant="primary"
        @click="selectedEntities.length && handleRun()"
      >
        <span class="align-middle">{{ $t('Run') }}</span>
      </b-button>
      <b-tooltip
        v-if="!selectedEntities.length"
        target="search__run"
        placement="left"
      >
        {{ $t('advanced-search-disabled-run-text') }}
      </b-tooltip>

      <b-button
        v-if="viewOnly"
        id="search__edit"
        :class="{ 'icon-disabled': !$can('Update', 'Data_Source') }"
        variant="primary"
        @click="$can('Update', 'Data_Source') && $emit('editMode')"
      >
        <span class="align-middle">{{ $t('Edit') }}</span>
      </b-button>
      <b-tooltip v-if="!canUpdate" target="search__edit" placement="left">
        {{ $t(`no-edit-${$route.params.queryType}-query-permission-text`) }}
      </b-tooltip>
    </div>

    <b-container class="data-source-form-container" fluid>
      <b-row>
        <b-col xl="3">
          <!-- START: Selector columns -->
          <div>
            <ProzessEntitySelector
              :collapsable="false"
              :view-mode="viewMode"
              :options="entityOptions"
              :selections="selectedEntities"
              @item-click="(item) => handleTableClick(item)"
            >
              <template slot="collapsed-content">
                {{ selectedTablesCount }} {{ $t('tables selected') }}
              </template>
            </ProzessEntitySelector>
          </div>
        </b-col>
        <b-col xl="3">
          <div>
            <ProzessEntityFieldSelector
              store="advancedSearch"
              :collapsable="false"
              :view-mode="viewMode"
              :options="fieldOptions"
              :toggle-text="$t('Filter')"
            >
              <template slot="collapsed-content">
                {{ selectedFieldsCount }} {{ $t('fields selected') }}
              </template>
            </ProzessEntityFieldSelector>

            <!-- END: Selector columns -->
          </div>
        </b-col>
        <b-col xl="6">
          <!-- START: Results column -->
          <div class="search__column column--results tw-mt-4">
            <h4 class="tw-mb-4 tw-flex tw-inline-block tw-text-body">
              {{ $t('Results') }}
            </h4>
            <div class="column__content tw-p-6 tw-rounded-lg">
              <div
                v-if="!showResultsTable"
                class="tw-text-lg tw-text-center tw-text-muted tw-mt-5"
              >
                {{ $t('No Results Loaded') }}
              </div>
              <AdvancedSearchResults
                v-show="showResultsTable"
                :is-shared="true"
                :form-action="formAction"
                :resource="resource"
              />
            </div>
            <!-- END: Results column -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { Entity } from '@/constants/app'
import { searchModelSchema } from '@/schema/advancedSearch'
import { FormAction, QueryType } from '@/constants/advancedSearch'
import advancedSearchService from '@/services/advancedSearch'
import AdvancedSearchResults from '@/components/AdvancedSearch/AdvancedSearchResults.vue'
import RootEvents from '@/constants/rootEvents'
import ProzessEntitySelector from '@/components/shared/ProzessEntitySelector.vue'
import ProzessEntityFieldSelector from '@/components/shared/ProzessEntityFieldSelector.vue'
import prozessEntitySelectorMixin from '@/mixins/prozessEntitySelector'
import { dashboardService } from '@/services/dashboard'

export default {
  components: {
    AdvancedSearchResults,
    ProzessEntitySelector,
    ProzessEntityFieldSelector,
  },

  mixins: [
    /**
     * !! ATTENTION !!
     * Read the comments on top of the `@/mixins/prozessEntitySelector.js` file first before implementing this mixin.
     *
     * DO NOT DELETE THIS COMMENT.
     */
    prozessEntitySelectorMixin('advancedSearch'),
  ],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    viewOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      resource: null,
      FormAction,
      isRunning: false,
      entityOptions: [],
    }
  },

  computed: {
    ...mapState({
      entities: ({ advancedSearch }) => advancedSearch.entities,
      selectedEntities: ({ advancedSearch }) => advancedSearch.selectedEntities,
      fieldOptions: ({ advancedSearch }) => advancedSearch.fieldOptions,
      result: ({ advancedSearch }) => advancedSearch.result,
      id: ({ dashboard }) => dashboard.id,
    }),

    ...mapGetters({
      primaryEntityId: 'advancedSearch/primaryEntityId',
      filterOptions: 'advancedSearch/filterOptions',
      selectedTablesCount: 'advancedSearch/selectedTablesCount',
      selectedFieldsCount: 'advancedSearch/selectedFieldsCount',
      selectedFiltersCount: 'advancedSearch/selectedFiltersCount',
      filteringFields: 'advancedSearch/filteringFields',
    }),

    showResultsTable() {
      return this.isRunning
    },

    canUpdate() {
      const queryType = this.$route.params.queryType === QueryType.GLOBAL
        ? 'Global_Query'
        : 'Personal_Query'

      return this.$can('Update', queryType)
    },

    viewMode() {
      return this.viewOnly
    },

    canRun() {
      return [FormAction.CREATE, FormAction.EDIT].includes(this.formAction)
    },

    formAction() {
      if (this.viewOnly && this.id) return 'view'
      if (!this.viewOnly && this.id) return 'edit'
      return 'create'
    },
  },

  watch: {
    '$route.name': {
      handler(value) {
        this.resetLocalState()
        this.resetStore()
        this.$nextTick(async () => {
          await this.getEntities()

          if (this.id) {
            this.getQueryData()
          }
        })
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      clearAll: 'advancedSearch/CLEAR_ALL',
      setErrors: 'advancedSearch/SET_ERRORS',
      updateFieldFilteringState: 'advancedSearch/UPDATE_FIELD_FILTERING_STATE',
      setFilterValue: 'advancedSearch/SET_FILTER_VALUE',
      setFilterCondition: 'advancedSearch/SET_FILTER_CONDITION',
      showDataSourceForm: 'dashboard/SET_DATASOURCE_FORM_VISIBILITY',
      setId: 'dashboard/SET_ID',
    }),

    async getEntities() {
      const { response } = await this.$async(
        dashboardService().dataSourceEntities(),
      )

      const entities = response.data
        .filter(({ key }) => key !== Entity.DOCUMENT)
        .map(entity => {
          entity.id = `${entity.schema}.${entity.key}`
          return entity
        })

      this.setEntities(entities)
      this.resetEntityOptions()
    },

    async getQueryData() {
      const { response } = await this.$async(
        dashboardService('datasource').getOne(this.id),
      )
      this.setInitialData(response.data)
    },

    handleClearClick() {
      this.resetLocalState()
      this.resetStore()
      this.$nextTick(() => this.getEntities())

      this.$root.$emit(RootEvents.ADV_SEARCH_CLEAR)
      this.showDataSourceForm(false)
      this.setId(null)
    },

    async handleRun() {
      const errors = await this.validateSearchModel()
      if (errors.length) {
        return false
      }

      this.isRunning = true
      this.$root.$emit(RootEvents.ADV_SEARCH_RUN)
    },

    resetLocalState() {
      this.isRunning = false
      this.entityOptions = []
    },

    setInitialData(searchData) {
      this.resource = searchData

      // this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.resource.name)

      const entities = this.setInitialSelectedEntities(
        this.resource.configuration,
      )

      this.setInitialDateFilter()

      this.$nextTick(() => {
        this.setInitialSelectedFields(entities)
      })
    },

    setInitialDateFilter() {
      // set dateFilter
      this.resource.configuration.entities.forEach(entity => {
        const entityId = `${entity.schemaName}.${entity.tableName}`
        if (entity.dateFilter != null) {
            const dfPayload = {
              entityId: `${entity.schemaName}.${entity.tableName}`,
              fieldKey: entity.dateFilter,
            }
            this.setDateFilter(dfPayload)
        }
      })
    },

    setInitialSelectedEntities(searchModel) {
      const entities = searchModel.entities.map(entity => {
        const id = `${entity.schemaName}.${entity.tableName}`
        return {
          ...this.entities.find(({ id: entityId }) => entityId === id),
          ...entity,
        }
      })

      entities.forEach(entity => this.$nextTick(() => this.handleTableClick(entity)))
      return entities
    },

    async setInitialSelectedFields(entities) {
      entities.forEach(entity => {
        this.toggleCollapsedState(entity.id)
        entity.selectedFields.forEach(field => {
          this.addSelectedField({ entityId: entity.id, fieldKey: field.key, query: field.query })
        })
      })

      this.handleRun()
    },

    async validateSearchModel() {
      const filters = this.filteringFields.reduce((_filters, field) => {
        const prefix = `${field.entitySchema}_${field.entityKey}_${field.key}`

        _filters[`${prefix}_input`] = field.filterValue
        _filters[`${prefix}_condition`] = field.filterCondition

        return _filters
      }, {})

      const errors = await this.yupValidate(
        searchModelSchema(this.filteringFields),
        filters,
      )

      this.setErrors(errors)

      return errors
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';

.data-source-form-container {
  .column__content {
    background: var(--colour--card-bg);
  }
  .column__content {
    $approx-nav-footer-height: 210px;
    height: calc(90vh - #{$approx-nav-footer-height});
    overflow: auto;
  }

  .advanced-search__results {
    height: 100%;
  }

  .result-table-container {
    max-height: 89%;
    max-width: 100%;
    overflow: auto;
  }
}
</style>

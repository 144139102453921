<template>
  <div ref="resultsColumn" class="advanced-search__results">
    <div
      v-if="canSave"
      class="search__actions tw-flex tw-justify-end tw-mb-2"
    >
      <b-button
        id="btn--export"
        variant="outline-primary"
        class="mr-1 disable"
        @click="initiateExport"
      >
        <feather-icon icon="DownloadIcon" class="tw-mr-2" />
        <span class="align-middle">{{ $t('Export') }}</span>
      </b-button>
      <b-button id="btn--save" variant="primary" :class="{ 'icon-disabled': !selectedTablesCount }" @click="selectedTablesCount && handleSaveClick()">
        <feather-icon icon="SaveIcon" class="tw-mr-2" />
        <span class="align-middle">{{ $t('Save') }}</span>
      </b-button>
    </div>
    <div class="tw-my-4 result-table-container">
      <b-table
        v-if="renderTable"
        ref="refTable"
        :busy="loading"
        :items="items"
        :responsive="!isShared"
        :fields="fieldColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        :class="{'table-responsive' : !isShared}"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label }">
          <span style="white-space: nowrap">{{ $t(label) }}</span>
        </template>

        <template #cell(title)="{ item }">
          <span class="text-nowrap">
            {{ item.name }}
          </span>
        </template>
      </b-table>
    </div>
    <prozess-pagination
      v-if="!loading"
      class="mx-1 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @change="handlePageChange"
    />

    <AdvancedSearchSaveModal v-if="!isShared" />

    <DashboardWidgetSaveModal v-else />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { FormAction } from '@/constants/advancedSearch'
import advancedSearchService from '@/services/advancedSearch'
import RootEvents from '@/constants/rootEvents'
import AdvancedSearchSaveModal from '@/components/AdvancedSearch/AdvancedSearchSaveModal.vue'
import formatDynamicColumn from '@/mixins/formatDynamicColumn'
import scrollToRefMixin from '@/mixins/scrollToRef'
import DashboardWidgetSaveModal from '@/components/Dashboard/DashboardWidgetSaveModal.vue'

export default {
  components: {
    AdvancedSearchSaveModal,
    DashboardWidgetSaveModal,
  },

  mixins: [scrollToRefMixin, formatDynamicColumn],

  props: {
    formAction: {
      type: String,
      required: true,
    },

    isShared: {
      type: Boolean,
      default: false,
    },

    resource: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      type: 'advanced-search',
      FormAction,
      loading: false,
      currentPage: 1,
      perPage: 25,
      sortBy: this.$route.query.sortBy || '',
      sortDesc: this.$route.query.sortDesc === 'true',
      renderTable: false,
      searchModel: null,
    }
  },

  computed: {
    ...mapState({
      result: ({ advancedSearch }) => advancedSearch.result,
      fieldOptions: ({ advancedSearch }) => advancedSearch.fieldOptions,

    }),

    ...mapGetters({
      selectedFieldsCount: 'advancedSearch/selectedFieldsCount',
      selectedTablesCount: 'advancedSearch/selectedTablesCount',
    }),

    canSave() {
      return [FormAction.CREATE, FormAction.EDIT].includes(this.formAction)
    },

    showSave() {
      return !!this.selectedTablesCount
    },

    items() {
      return this.result.pageItems
    },

    total() {
      return this.result.filteredItemsCount
    },

    dataMeta() {
      const localItemsCount = this.items.length

      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },

  watch: {
    '$route.query': {
      handler(query) {
        if (query.runId) {
          this.runQuery()
        }
      },
    },
  },

  mounted() {
    this.registerBusEvent(RootEvents.ADV_SEARCH_RUN, () => {
      this.searchModel = this.$store.getters['advancedSearch/searchModel']

      this.rerenderTable()
      this.updateRouteQuery({ page: 0, runId: this.generateRunId() })
    })

    this.registerBusEvent(RootEvents.ADV_SEARCH_CLEAR, () => {
      if (Object.keys(this.$route.query).length) {
        this.updateRouteQuery(null, true)
      }
    })
  },

  destroyed() {
    this.$root.$off(RootEvents.ADV_SEARCH_RUN)
    this.$root.$off(RootEvents.ADV_SEARCH_CLEAR)
  },

  methods: {
    ...mapMutations({
      setResults: 'advancedSearch/SET_RESULT',
    }),

    formatApiFilters() {
      const { page, sortDesc, sortBy } = this.$route.query
      this.sortBy = sortBy
      this.currentPage = +page || 1

      if (sortBy) {
        this.sortDesc = sortDesc === 'true' || sortDesc === true
        this.setSearchModelOrdering(sortBy)
      }

      return {
        page: +page ? page - 1 : 0,
        size: 25,
      }
    },

    generateRunId() {
      return this.$uuidv4().split('-')[0]
    },

    handleSortChange({ sortBy, sortDesc }) {
      this.updateRouteQuery({ ...this.$route.query, sortBy, sortDesc })
    },

    handlePageChange(page) {
      const { page: queryPage, runId } = this.$route.query
      if (!page || page === parseInt(queryPage) || !runId) {
        return
      }

      this.updateRouteQuery({ ...this.$route.query, page })
    },

    handleSaveClick() {
      this.$root.$emit(RootEvents.ADV_SEARCH_CONFIRM_SAVE, { data: this.resource, open: true })
    },

    async initiateExport() {
      await this.$async(advancedSearchService.initiateExport(this.searchModel))

      this.$swal({
        title: this.$t('advanced-search-export-notice'),
        icon: 'info',
        confirmButtonText: this.$t('Close'),
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
    },

    rerenderTable() {
      this.resetTableSorting()

      this.renderTable = false
      this.$nextTick(() => {
        this.renderTable = true
      })
    },

    resetTableSorting() {
      this.sortBy = null
      this.sortDesc = null
    },

    async runQuery() {
      this.loading = true
      const payload = [this.searchModel, this.formatApiFilters()]
      console.log('GGGGGG runQuery', payload)
      let request

      if (!this.isShared) {
        const { queryType, id } = this.$route.params

        request = this.canSave
          ? advancedSearchService.runUnsavedQuery(...payload)

          : advancedSearchService.runSavedQuery(queryType, id, ...payload)
      } else request = advancedSearchService.runUnsavedQuery(...payload)
      const { response } = await this.$async(request)

      if (response) {
        this.setResults(response.data)
        this.$nextTick(() => this.scrollToRef('resultsColumn', 100))
      }

      this.loading = false
    },

    setSearchModelOrdering(sortKey) {
      const {
        schemaName,
        tableKey,
        column: key,
        customField,
      } = this.breakDownColumnKey(sortKey)

      this.searchModel.orders = [
        {
          field: {
            schemaName,
            tableKey,
            fieldInEntity: {
              key,
              customField,
            },
          },
          desc: this.sortDesc,
        },
      ]
    },

    updateRouteQuery(query, clear = false) {
      const to = { name: this.$route.name, params: this.$route.params, query }

      if (clear) {
        delete to.query
        this.resetTableSorting()
      }
      this.$router.push(to)
    },
  },
}
</script>
